import Dexie from 'dexie';

// TODO: ensure type interfaces are correct

interface IInfos {
   id: string;
   idDisplayWZ: string;
   idWarningZone: string;
   dateLastGet: Date;
   dateLastPost: Date;
   delta: any[];
   deltaSaved: boolean;
   structure: any;
   primaryContext: any;
   subcontextList: any;
   img: any;
}

interface ITemp {
   id: string;
   key: string;
   value: any;
   trick: 'trick';
}

class AnaisDatabase extends Dexie {
   infos!: Dexie.Table<IInfos, number>;
   temp!: Dexie.Table<ITemp, number>;

   constructor() {
      super('anaisDb');

      /** No need to keep previous version with dexie version >= 3 */
      this.version(19).stores({
         infos: '++id,idDisplayWZ,dateLastGet,dateLastPost,delta,deltaSaved,structure,primaryContext,subcontextList,img',
         temp: '++id,key,value,trick',
      });
   }
}

export default new AnaisDatabase();
