import './context-list.scss';

import Arrow from '@material-ui/icons/Home';
import Label from '@material-ui/icons/LabelImportant';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';

import ContextCategoryField from '../../components/ContextCategoryField';
import Media from '../../components/Media';
import TabNavContext from '../../components/TabNavContext';
import * as contextService from '../../core/helpers/contextHelper';
import strings from '../../core/localization/localization';
import { MessageContext } from '../../react-context/message-context';

const ContextList = ({ lang }) => {
   const { id, ctxId } = useParams();
   const { state: location } = useLocation();

   const { setMessage } = useContext(MessageContext);

   const [data, setData] = useState();
   const [activeIndex, setActiveIndex] = useState(0);
   const [imgMap, setImgMap] = useState(undefined);
   const [redirectOnError, setRedirectOnError] = useState(false);
   const [context, setContext] = useState();
   const element = useRef();

   useEffect(() => {
      !ctxId || setActiveIndex(+ctxId);
      (async () => {
         await loadContexts();
         handleScroll();
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      !data || setContext(activeIndex === 0 ? data.primaryContext : data.subcontextList[+activeIndex - 1]);
      setMapImagePath(activeIndex);
   }, [data, activeIndex]);

   const loadContexts = async () => {
      const result = await contextService.getZAObject(id);
      result ? setData(result) : setRedirectOnError(true);
   };

   const setMapImagePath = async (index) => {
      const fileEnd = '_mapImage.jpg';
      if (data) {
         const createPath = (part) => `/map-images-za/${data?.idWarningZone}${!!part ? `_${part}` : ''}${fileEnd}`;
         let img = createPath('');
         if (index > 0) {
            img = createPath(data.subcontextList[index - 1].mapImg);
         }
         setImgMap(img);
      }
   };

   const changeActiveIndex = (index) => {
      index = +index;
      setMapImagePath(index);
      setActiveIndex(index);
   };

   const fulfillIndex = (index) => {
      contextService.copyPrimaryContext(id, index, loadContexts);
   };

   const handleScroll = () => {
      if (location?.backFromContextEdit) {
         setTimeout(() => {
            element?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
         }, 500);
      } else {
         window.scroll({ top: 0, left: 0, behavior: 'auto' });
      }
   };

   if (redirectOnError) {
      setTimeout(() => {
         setMessage({
            text: strings.pwa_default_error_message,
            type: 'danger',
            timer: 5000,
         });
      }, 200);

      return <Navigate to="/" />;
   }

   return (
      <div className="ListContext_main-container" id="list-context">
         <Link to="/">
            <Arrow className="arrow-back" />
         </Link>
         <div className="ListContext_container">
            <div className="ListContext-block_infos">
               <div>
                  <Label />
                  {id}
               </div>
               <p>
                  {strings[data?.primaryContext?.mainRiskTitle]} : {data?.primaryContext?.mainRiskValue}
               </p>
            </div>
            <div className="ListContext-block_infos">
               <p>{strings.pwa_Context_Statement}</p>
            </div>
            <div className="ListContext-block_image">{!!id && <Media path={imgMap} alt={''} />}</div>
            <TabNavContext Contexts={data} activeIndex={activeIndex} callbackIndex={changeActiveIndex} callbackFulfill={fulfillIndex} />
            {context?.listFields.map((listField, i) => (
               <div
                  ref={(el) => {
                     if (location?.backFromContextEdit === listField.type) {
                        element.current = el;
                     }
                  }}
                  className="content_container"
                  key={`${i}-${listField.name}`}
               >
                  <ContextCategoryField index={activeIndex} structure={listField} data={data} id={id} key={`${id}_list`} lang={lang} />
               </div>
            ))}
         </div>
      </div>
   );
};

export default ContextList;
