import './dashboard.scss';

import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import Arrow from '@material-ui/icons/ExpandLess';
import Filter from '@material-ui/icons/FilterList';
import GpsIcon from '@material-ui/icons/NearMe';
import { useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import { callGetCacheForUserRefresh, endMission, getContextsFromZaList, getMissionWarningZones } from '../../API/api';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import config from '../../config/config';
import * as contextService from '../../core/helpers/contextHelper';
import { normalizeString, returnTop, toHtml } from '../../core/helpers/globalHelper';
import strings from '../../core/localization/localization';
import environment from '../../enviromnent';
import { MessageContext } from '../../react-context/message-context';
import { SyncContext } from '../../react-context/sync-context';

const Dashboard = ({ lang }) => {
   const sticky_after = 100;

   const headers_za = [
      { class: 'index-column', sort: 'idDisplayWZ', strings: 'pwa_page_list_index' },
      { class: 'city-column', sort: 'city', strings: 'pwa_page_list_city' },
      { class: 'road-column', sort: 'road', strings: 'pwa_page_list_road' },
      { class: 'context-column', sort: 'contextFillRate', strings: 'pwa_page_list_context' },
   ];

   const headers_missions = [
      { class: 'id-column', strings: 'pwa_mission_list_id' },
      { class: 'za-column', strings: 'pwa_mission_list_zas' },
      { class: 'action-column', strings: 'pwa_mission_list_actions' },
   ];

   const { isSynchronizing, setIsSynchronizing, unSyncedCount, setUncyncedCount } = useContext(SyncContext);

   const { setMessage } = useContext(MessageContext);
   const { intervalCacheCall } = environment;

   const mounted = useRef(true);

   const [warningZones, setWarningZones] = useState(undefined);
   const [missionList, setMissionList] = useState([]);
   const [missionSelected, setMissionSelected] = useState(undefined);
   const [missionComment, setMissionComment] = useState('');
   const [zaSortAsc, setZaSortAsc] = useState(true);
   const [sortedZaCol, setSortedZaCol] = useState('');
   const [missionSortAsc, setMissionSortAsc] = useState(false);
   const [visible, setVisible] = useState(true);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);

   useEffect(() => {
      contextService.deleteTempValue();

      loadWorkSites();
      getCacheForUserRefresh();
      window.addEventListener('scroll', handleScroll);

      return () => {
         mounted.current = false;
         window.removeEventListener('scroll', handleScroll);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (warningZones) {
         sortZas('idDisplayWZ');
      }
   }, [warningZones]);

   const handleScroll = () => {
      setVisible(window.scrollY < sticky_after);
   };

   /**
    *
    * @param {import('../../core/models/warningZone').WarningZone[]} arr
    * @returns {import('../../core/models/warningZone').WarningZone[]}
    */
   const removeDuplicatesFromZaList = (arr) => {
      return [...new Map(arr.map((item) => [item.idDisplayWZ, item])).values()];
   };

   const fetchGetCacheForUserRefresh = () => {
      try {
         callGetCacheForUserRefresh().then((res) => {
            if (res) {
               loadWorkSites();
            }
         });
      } catch {}
   };

   const getCacheForUserRefresh = () => {
      const trimValue = intervalCacheCall.trim();
      const parsedValue = parseInt(trimValue, 10);
      if (!isNaN(parsedValue)) {
         setInterval(fetchGetCacheForUserRefresh, intervalCacheCall ? intervalCacheCall : 15000);
      } else {
         console.log(`Error while parsing cache interval call`);
      }
   };

   const getContexts = async (warningZones) => {
      const idsWZ = warningZones.map((wz) => wz.idWarningZone);
      const IdsWZDisplay = warningZones.map((wz) => wz.idDisplayWZ);
      await contextService.cleanContextDb(IdsWZDisplay);
      updateSyncNumber(true);
      try {
         const contextObj = await getContextsFromZaList(IdsWZDisplay);
         for (const warningZoneItem of warningZones) {
            const id = warningZoneItem.idDisplayWZ;
            if (!!contextObj[id]) {
               const response = await contextService.setZAObject(contextObj[id], warningZoneItem);
               !!response && (warningZoneItem.loadingOrFailed = false);
            }
         }
         setWarningZones(warningZones);
         contextService.cacheMapImages(contextObj);
      } catch (err) {
         console.log(err);
      } finally {
         setLoading(false);
      }
   };

   const loadWorkSites = async () => {
      setLoading(true);
      const data = await getMissionWarningZones();

      if (data) {
         const zaNoDuplicates = removeDuplicatesFromZaList(data);
         const zas = await contextService.checkDeltaCompletionPercentages(zaNoDuplicates);
         zas.forEach((warning) => (warning.loadingOrFailed = true));
         setWarningZones(zas);
         generateMissionList(data);
         if (!!data.length) {
            getContexts(zas);
         } else {
            contextService.cleanContextDb([]);
            setLoading(false);
         }
      } else {
         setError(true);
         setLoading(false);
      }
   };

   const generateMissionList = (zas) => {
      const map = new Map();
      zas.forEach((zaItem) => {
         const mission = map.get(zaItem.missionId);
         map.set(zaItem.missionId, [...(!!mission ? mission : []), { zaId: zaItem.idDisplayWZ, zaRoad: zaItem.road }]);
      });

      const missions = Array.from(map, ([id, zaList]) => ({ id, zaList }));
      setMissionList(missions);
   };

   const reloadWorksitesInfos = async () => {
      setLoading(true);
      const previousList = [...warningZones];
      const data = await getMissionWarningZones();
      if (data) {
         const zaNoDuplicates = removeDuplicatesFromZaList(data);
         /**
          * @type {import('../../core/models/warningZone').WarningZone[]}
          */
         const wzList = [];
         zaNoDuplicates.forEach((wz) => {
            const zone = previousList.find((item) => item.idDisplayWZ === wz.idDisplayWZ);
            if (!!zone) {
               wzList.push({ ...wz, loadingOrFailed: zone.loadingOrFailed });
            }
         });
         generateMissionList(data);
         setWarningZones(wzList);
      } else {
         setError(true);
         setWarningZones(undefined);
      }
      setLoading(false);
   };

   const sortZas = (type) => {
      let tempZaSortAsc = zaSortAsc;
      if (type !== sortedZaCol) {
         tempZaSortAsc = true;
      }

      const nb = tempZaSortAsc ? -1 : 1;
      const zas = warningZones.sort((a, b) => {
         if (normalizeString(a[type]) < normalizeString(b[type])) return nb;
         if (normalizeString(a[type]) > normalizeString(b[type])) return -nb;
         return 0;
      });

      setWarningZones(zas);
      setSortedZaCol(type);
      setZaSortAsc(!tempZaSortAsc);
   };

   const sortMissions = () => {
      const nb = missionSortAsc ? -1 : 1;
      const missions = missionList.sort((a, b) => {
         if (a.id < b.id) return nb;
         if (a.id > b.id) return -nb;
         return 0;
      });

      setMissionList(missions);
      setMissionSortAsc((prevMissionSortAsc) => !prevMissionSortAsc);
   };

   const mapsSelector = (lat, lon) => {
      if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('Android') !== -1) {
         window.open(`maps://maps.google.com/maps/dir/?api=1&destination=${lat},${lon}&dir_action=navigate`);
      } else {
         window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}&dir_action=navigate`);
      }
   };

   const updateSyncNumber = async (firstCall) => {
      const unSyncedNb = await contextService.getToSyncNumber();
      setUncyncedCount(unSyncedNb);

      if (!firstCall) {
         setIsSynchronizing(false);

         if (!!mounted.current) {
            reloadWorksitesInfos();
         }
      }
   };

   const sendValue = async () => {
      setIsSynchronizing(true);
      const result = await contextService.saveAllDataNow(lang);

      // if user has navigated away, component is unmounted. if so prevent the following if/else (avoid memory leak)
      if (!!result.success) {
         updateSyncNumber();
      } else {
         setMessage({
            text: strings.pwa_save_fail_message_dashboard,
            timer: 10000,
         });
         setIsSynchronizing(false);
      }
   };

   const closeMissionEndModal = () => {
      setMissionSelected(undefined);
      setMissionComment('');
   };

   const endSelectedMission = async () => {
      try {
         await endMission(missionSelected, toHtml(missionComment));
         closeMissionEndModal();
         reloadWorksitesInfos();
         setMessage({
            text: strings.pwa_mission_end_success,
            type: 'success',
            timer: 5000,
         });
      } catch (err) {
         setMessage({
            text: strings.pwa_default_error_message,
            timer: 10000,
         });
      }
   };
   return (
      <>
         <div className="dashboard">
            <div className="list">
               <div className="list-title-container">
                  <h2 className="list-title">{strings.pwa_page_list_title}</h2>
                  <button disabled={isSynchronizing || loading || unSyncedCount === 0} onClick={sendValue}>
                     {strings.pwa_data_synchronisation}
                     &nbsp;
                     {isSynchronizing || loading ? <Loader type="ThreeDots" color="#000" height={20} width={20} /> : `(${unSyncedCount})`}
                  </button>
               </div>
               <div className="list-content">
                  <div className="list-header">
                     {headers_za.map((header, i) => (
                        <div className={`${header.class} list-header-tab`} onClick={() => sortZas(header.sort)} key={`item-${i}`}>
                           {strings[header.strings]} <Filter />
                        </div>
                     ))}
                  </div>
                  {!!warningZones && !!warningZones.length ? (
                     warningZones.map((warning) => {
                        const { loadingOrFailed } = warning;
                        return (
                           <div key={warning.idDisplayWZ} id={`za-id-${warning.idDisplayWZ}`} className="list-item">
                              <div className="index-column clickable-area" onClick={() => mapsSelector(warning.criticalPoint[0], warning.criticalPoint[1])}>
                                 <GpsIcon />
                                 {warning.idDisplayWZ}
                              </div>
                              <div className="city-column">{warning.city}</div>
                              <div className="road-column">{warning.road}</div>
                              <div className="context-column clickable-area">
                                 <Link to={loadingOrFailed ? '#' : `ContextList/` + warning.idDisplayWZ}>
                                    <div className="context-column-percentage">{warning.contextFillRate === null ? 0 : warning.contextFillRate} %</div>
                                    <div className="context-column-icon">
                                       {!!loadingOrFailed ? <Loader type="ThreeDots" color="#000" height={120} width={120} /> : <EditIcon />}
                                    </div>
                                 </Link>
                              </div>
                           </div>
                        );
                     })
                  ) : (
                     <div className="no-mission">
                        {!!loading ? '' : !!error ? <div style={{ color: 'red' }}>{strings.pwa_default_error_message}</div> : strings.pwa_page_list_no_mission}
                     </div>
                  )}
               </div>
            </div>
            <div className="list">
               <div className="list-title-container">
                  <h2 className="list-title">{strings.pwa_missions_in_progress}</h2>
               </div>
               <div className="list-content">
                  <div className="list-header list-header--mission">
                     {headers_missions.map((header, i) => {
                        const noSort = header.class !== 'id-column';
                        return (
                           <div className={`${header.class} list-header-tab ${noSort ? 'not-clickable' : ''}`} onClick={() => sortMissions()} key={`item-${i}`}>
                              {strings[header.strings]} {!noSort && <Filter />}
                           </div>
                        );
                     })}
                  </div>
                  {missionList.map((missionItem) => (
                     <div key={missionItem.id} className="list-item">
                        <div className="id-column">{missionItem.id}</div>
                        <div className="za-column mission-za-list">
                           {missionItem.zaList.map((za) => (
                              <div key={`mission-${missionItem.id}_za-${za.zaId}`} className="mission-za-list-item">
                                 N°{za.zaId}
                                 &nbsp;-&nbsp;
                                 {za.zaRoad}
                              </div>
                           ))}
                        </div>
                        <div className="action-column">
                           <div className="action clickable-area">
                              <Link to={`mission/${missionItem.id}`} state={{ zaList: JSON.stringify(missionItem.zaList) }} />
                              <ChatBubbleOutlineIcon />
                           </div>
                           <div className="action clickable-area" onClick={() => setMissionSelected(missionItem.id)}>
                              <CheckBoxIcon />
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
         <Arrow className={'return-to-top' + (!visible ? '--show' : '--hidden')} onClick={returnTop} />
         {missionSelected !== undefined && (
            <Modal.Dialog>
               <Modal.Header close={closeMissionEndModal}>{strings.pwa_mission_end_title}</Modal.Header>
               <Modal.CommentBox maxlength={config.missionCommentMaxLength} value={missionComment} onChange={(e) => setMissionComment(e.target.value)}>
                  {strings.pwa_mission_comment_label}
               </Modal.CommentBox>
               <Modal.Footer>
                  <Button onClick={endSelectedMission}>{strings.pwa_mission_button_end}</Button>
               </Modal.Footer>
            </Modal.Dialog>
         )}
      </>
   );
};

export default Dashboard;
