import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link, Navigate } from 'react-router-dom';
import './mission.scss';

import { getMission, postMissionComment } from '../../API/api';
import strings from '../../core/localization/localization';

import { MessageContext } from '../../react-context/message-context';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import config from '../../config/config';
import { IMission, IMissionHistory } from '../../core/interfaces/IMission';

import { LangPropsType } from '../../App';
import { getDate, getShortDate, getTime } from '../../core/helpers/datesHelper';
import { returnTop, sanitizeHtmlString, toHtml } from '../../core/helpers/globalHelper';

const Mission: React.FC<LangPropsType> = ({ lang }) => {
   const { id } = useParams();
   const location = useLocation() as { state: { zaList: string } };

   const { setMessage } = useContext(MessageContext);

   const [mission, setMission] = useState<IMission | undefined>(undefined);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [comment, setComment] = useState<string>('');
   const [errorRedirect, setErrorRedirect] = useState(false);

   const reason: { [key in number | string]: string } = {
      [config.missionStatus.ended]: 'pwa_mission_action_end',
      [config.missionStatus.canceled]: 'pwa_mission_action_canceled',
      [config.missionStatus.active]: 'pwa_mission_action_reactivated',
      [config.missionStatus.closed]: 'pwa_mission_action_closed',
      created: 'pwa_mission_action_creation',
      reassigned: 'pwa_mission_action_reassignment',
   };

   const processMission = useCallback(
      (arr: IMission) => {
         // za list is passed from Dashboard component as it contains only ZAs that are open (and excludes those that are closed)
         // mission endpoint contains za list even of those that are closed (we do not want user to access closed ZA)
         if (!!location.state && !!location.state.zaList) {
            arr.warningZones = JSON.parse(location.state.zaList).map(({ zaId, zaRoad: name, idDisplayWZ }: { zaId: string; zaRoad: string; idDisplayWZ: string }) => ({
               id: zaId,
               name: name,
               idDisplayWZ: idDisplayWZ,
            }));
         }

         arr.history.forEach((item: IMissionHistory, i: number) => {
            let action: string | number | null;

            if (i === arr.history.length - 1 && item.status === config.missionStatus.active) {
               action = 'created';
            } else if (!!item.assignmentId || !!item.assignmentId2) {
               action = 'reassigned';
            } else if (item.status === arr.history[i + 1].status) {
               // actionless comment
               action = null;
            } else {
               action = item.status;
            }

            item.action = action;
         });
      },
      [location.state]
   );

   useEffect(() => {
      (async () => {
         try {
            const data = await getMission(id);
            processMission(data);
            setMission(data);
         } catch (error) {
            console.error(error);
            setErrorRedirect(true);
         }
      })();
   }, [id, processMission]);

   const closeCommentModal = () => {
      setOpenModal(false);
      setComment('');
   };

   const submitComment = async () => {
      try {
         if (id === undefined) {
            throw new Error();
         }
         const data = await postMissionComment(+id, toHtml(comment));
         processMission(data);
         setMission(data);
         closeCommentModal();
         returnTop();
         setMessage({
            text: strings.pwa_mission_comment_success,
            type: 'success',
            timer: 3000,
         });
      } catch (error) {
         console.error(error);
         setMessage({
            text: strings.pwa_default_error_message,
            type: 'danger',
            timer: 7000,
         });
      }
   };

   if (!!errorRedirect) {
      setTimeout(() => {
         setMessage({
            text: strings.pwa_default_error_message,
            type: 'danger',
            timer: 10000,
         });
      }, 200);
      return <Navigate to="/" />;
   }

   return (
      <>
         {mission !== undefined && (
            <div className="mission">
               <div className="title-line-across">{strings.pwa_mission_title_mission}</div>
               <div className="mission-section">
                  <div className="section-title">{strings.pwa_mission_zas_to_inspect}</div>
                  <div className="section-content">
                     <div className="za-list">
                        {mission.warningZones.map((za) => {
                           return (
                              <div className="za-item" key={`za-${za.id}`}>
                                 N°{za.id}
                                 &nbsp;-&nbsp;
                                 {za.name}
                                 <Link to={`/ContextList/${za.id}`} />
                              </div>
                           );
                        })}
                     </div>
                  </div>
               </div>
               <div className="mission-section">
                  <div className="section-title">{strings.pwa_mission_target_date}</div>
                  <div className="section-content">{getShortDate(mission.targetDate)}</div>
               </div>
               <div className="title-line-across mt-4">{strings.pwa_mission_title_history}</div>
               <div className="mission-section">
                  <div className="history">
                     {mission.history.map((item, i) => (
                        <React.Fragment key={`history-item-${i + 1}`}>
                           <div className="history__item">
                              <div className="history__item__info">
                                 <div className="deco" />
                                 <div className="history__item__info__author">
                                    <span className="history__item__info__author__name">{item.authorName}</span>
                                    &nbsp;
                                    <span className="history__item__info__author__time">
                                       {strings.pwa_mission_date_on}&nbsp;
                                       {getDate(item.creationDate)}&nbsp;
                                       {strings.pwa_mission_time_at}&nbsp;
                                       {getTime(item.creationDate)}
                                    </span>
                                 </div>
                              </div>
                              {!!item.action && (
                                 <div className="history__item__action">
                                    <div className="history__item__action__label">{strings.pwa_mission_history_label_action}</div>
                                    &nbsp;&nbsp;
                                    <div className="history__item__action__reason">{strings[reason[item.action]]}</div>
                                 </div>
                              )}
                              {!!item.text && <div className="history__item__comment" dangerouslySetInnerHTML={sanitizeHtmlString(item.text)} />}
                              {(!!item.assignmentId || !!item.assignmentId2) && (
                                 <div className="history__item__assignment">
                                    {[item.assignmentName, item.assignmentName2].map((agent, index) => {
                                       if (!!agent) {
                                          return (
                                             <div className="history__item__assignment__agent" key={`agent-${index + 1}`}>
                                                <div className="history__item__assignment__agent__label">
                                                   {strings.pwa_mission_history_label_agent} {index + 1} :
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="history__item__assignment__agent__name">{agent}</div>
                                             </div>
                                          );
                                       }
                                       return null;
                                    })}
                                 </div>
                              )}
                           </div>
                           <div className="history__item-separator" />
                        </React.Fragment>
                     ))}
                  </div>
               </div>
               <div className="mission-comment-button">
                  <Button onClick={() => setOpenModal(true)}>{strings.pwa_mission_button_add_comment}</Button>
               </div>
            </div>
         )}
         {openModal && (
            <Modal.Dialog>
               <Modal.Header close={closeCommentModal} />
               <Modal.CommentBox maxlength={config.missionCommentMaxLength} value={comment} onChange={(e) => setComment(e.target.value)}>
                  {strings.pwa_mission_comment_label}
               </Modal.CommentBox>
               <Modal.Footer>
                  <Button disabled={!comment.trim()} onClick={submitComment}>
                     {strings.pwa_default_submit}
                  </Button>
               </Modal.Footer>
            </Modal.Dialog>
         )}
      </>
   );
};

export default Mission;
